<template>
  <div data-app>
    <div class="quiz-event" v-if="!success"> 
      <div class="floating-link">
        <a href="https://www.sigmaaldrich.com/KR/ko/campaign-kr/green-alternatives" class="link">
          <img src="@/assets/images/common/icon_back.png" class="icon">
          <span class="text">머크 <span class="vdn">Green Alternatives</span> <br/>페이지로 돌아가기</span>
        </a>
      </div>
      <h1 class="logo"><img src="@/assets/images/common/logo_merck_blue.png" alt="merck"></h1>
      <div class="event-title">
        <div class="event-title__icon"><img class="icon-sprout" src="@/assets/images/main/greener/img_sprout.png" alt="earth"></div>
        <div class="event-title__campaign"><img src="@/assets/images/main/greener/img_campaign.png" alt="Greener Research, Greener solution"></div>
        <div class="event-title__title">퀴즈 풀<span class="vdn">GO,</span> 득템하<span class="vdn">GO</span></div> 
      </div>
      <div class="event-date">
        <div class="date-list">
          <div class="label">진행 기간</div>
          <div class="date"><span class="vdn">12</span>월 <span class="vdn">31</span>일 까지 <span class="sub">(매월 추첨 진행)</span></div>
        </div>
        <div class="date-list">
          <div class="label"><span class="vdn">8</span>월 당첨자 발표</div>
          <div class="date"><span class="vdn">9</span>월 <span class="vdn">6</span>일, <span class="vdn">100</span>명 <span class="sub">(개별 문자 안내)</span></div>
        </div>
      </div> 
      <div class="event-summary">
        <div class="summary1"><span class="vdn">OX</span> 퀴즈 풀고,<br/>친환경 <span class="vdn semi-bold">Solution</span>에 대해 알아보세요.</div>
        <div class="summary2">매월 정답자를 추첨하여<br/>에코백과 커피 기프티콘을 드립니다.</div>
        <div class="summary3">(*중복 참여 제외)</div> 
      </div>
      <div class="event-gift">
        <img src="@/assets/images/main/greener/img_gift.png" alt="gift" class="img-gift">
      </div>
      <div class="event-contents"> 
        <img class="event-contents__icon" src="@/assets/images/main/greener/img_earth_girl.png" alt="wind">
        <div class="content">
          <div class="event-quiz-form" v-if="showForm">
            <div class="event-quiz-form__title">
              <div class="main">자, 준비 되셨나요?</div>
              <div class="sub">먼저 참여 전에<br/><b>경품 발송을 위한 정보를 입력</b>해주세요!</div>
            </div>
            <v-form ref="form" v-model="valid" class="event-form">
              <label class="event-form__label">이름</label>
              <v-text-field
                v-model="form.name" 
                :rules="nameRules"
                color="#0F69AF" 
                class="event-form__input"
                required
              ></v-text-field> 
              <label class="event-form__label">소속</label>
              <v-text-field
                v-model="form.group" 
                :rules="groupRules"
                color="#0F69AF" 
                class="event-form__input"
                required
              ></v-text-field> 
              <label class="event-form__label">이메일 주소</label>
              <v-text-field
                type="email"
                v-model="form.email" 
                :rules="emailRules"
                color="#0F69AF" 
                class="event-form__input"
                required
              ></v-text-field> 
              <label class="event-form__label">휴대폰 번호</label>
              <v-text-field
                type="tel"
                v-model="form.phone" 
                :rules="phoneRules"
                color="#0F69AF" 
                class="event-form__input"
                hint="하이픈(-) 제외"
                required
              ></v-text-field> 
              <label class="event-form__label">경품 당첨 시 배송 주소</label>
              <v-text-field
                v-model="form.address" 
                :rules="addressRules"
                color="#0F69AF" 
                class="event-form__input"
                required
              ></v-text-field> 
              <v-checkbox 
                v-model="form.check1"
                :rules="checkRules"
                color="#0F69AF" 
                class="event-form__checkbox" 
                hide-details  
                :ripple="false"
              >
                <template v-slot:label>
                  <span class="checkbox-label"><b><span class="vdn">1</span>. [필수]</b> 개인정보 수집 동의</span>
                </template>
              </v-checkbox>
              <div class="event-form__terms">
                본인은 대한민국 개인정보보호법령 및 유럽 일반개인정보호규정(GDPR)에 따라 머크와의 거래관계 설정 및 유지, 계약의 이행을 위하여 성명, 소속 기관명, 부서 및 직함, 전화번호, 휴대전화번호, 전자우편주소, 주소의 개인 정보를 제공하는 것에 동의합니다. 수집된 개인 정보는 개인정보의 수집 및 이용 목적이 달성되면 지체없이 파기하되, 관계 법령의 규정에 따라 귀하의 개인정보를 보존할 의무가 있는 경우에 해당 법령에서 정한 바에 의하여 보유할 수 있습니다. 귀하는 언제든지 <a href="https://www.sigmaaldrich.com/KR/ko/life-science/legal/privacy-statement#privacy6" target="_blank" rel="noopener noreferrer">개인정보의 수집 및 이용 동의를 철회</a>할 수 있습니다. 보다 자세한 내용은 머크의 <a href="https://www.sigmaaldrich.com/KR/ko/life-science/legal/privacy-statement" target="_blank" rel="noopener noreferrer">개인정보 처리방침</a> 문서를 참고하실 수 있습니다.
              </div>
              <v-checkbox
                v-model="form.check2"
                :rules="checkRules"
                color="#0F69AF" 
                class="event-form__checkbox" 
                hide-details  
                :ripple="false"
              >
                <template v-slot:label>
                  <span class="checkbox-label"><b><span class="vdn">2</span>. [필수]</b> 개인정보 제 3자 제공 동의</span>
                </template>
              </v-checkbox>
              <div class="event-form__terms">
                본 이벤트 내 이용자 경품 배송 업무를 위해 개인정보를 위탁합니다. 정보는 위탁 목적 달성 후 지체없이 파기됩니다. 아래 동의를 거부 할 권리가 있으며, 동의를 거부할 경우 이벤트 참여가 제한됩니다.<br/><b>1. [개인정보 받는 자]: ㈜인스앤<br/>2. [위탁 목적]: 이벤트 경품 배송</b>
              </div>
              <v-checkbox
                v-model="form.checkMarketing" 
                color="#0F69AF" 
                class="event-form__checkbox" 
                hide-details  
                :ripple="false"
              >
                <template v-slot:label>
                  <span class="checkbox-label"><b><span class="vdn">3</span>. [선택]</b> 마케팅 수신 동의</span>
                </template>
              </v-checkbox>
              <div class="event-form__terms">
                본인은 머크에 제공된 상기 정보가 우편(전자우편 포함) 발송, 방문, 전화(휴대전화, 유선전화, 팩스 등), 인터넷(카페, 블로그, SNS 쪽지 등), 메신저 등을 통한 제품설명회 등 마케팅, 시장조사 및 회사 제품의 안내를 포함한 제품 정보 전달 및 취득 활동의 수행 등에 이용될 수 있음을 명확히 이해했고 이에 동의합니다.
              </div>
              <v-btn
                :disabled="!valid"
                color="#0F69AF" 
                @click="startQuiz(form)"
                depressed
                class="event-form__button"
              >퀴즈 시작하기</v-btn>
            </v-form> 
          </div>
          <div class="event-quiz" v-else> 
            <div class="event-quiz__num">QUIZ {{questions[currentQuizIndex].num}}</div>
            <div class="event-quiz__question" v-html="questions[currentQuizIndex].text"></div>
            <div class="event-quiz__answer">
              <div class="btn-anwser button-o" @click="clickTrue(questions[currentQuizIndex].answer)"></div>
              <div class="btn-anwser button-x" @click="clickfalse(questions[currentQuizIndex].answer)"></div>
            </div>
            <div class="event-quiz__hint">
              <div class="icon"><img src="@/assets/images/main/greener/icon_hint.png" alt="hint"></div>
              <div class="text" v-html="questions[currentQuizIndex].hint"></div>
            </div>    
          </div> 
        </div>
      </div>  
      <v-overlay :value="isLoading" :opacity="0.2"> 
        <v-btn icon>
          <v-progress-circular
            :size="50"
            color="#149B5F"
            indeterminate
          ></v-progress-circular>
        </v-btn>
      </v-overlay> 
    </div> 
    <GreenerEventSuccess v-else />
    <v-dialog
      v-model="dialog"
      width="480"
    >  
      <v-card class="event-dialog">
        <div class="event-dialog__icon"><img src="@/assets/images/common/icon_error_blue.png" alt="error"></div>
        <div class="event-dialog__text">
          <div class="main">정답이 아닙니다.</div>
          <div class="text">힌트를 참고하여, 다시 시도해보세요.</div>
        </div> 
        <v-btn 
          block
          depressed
          tile
          color="#149B5F"  
          @click="dialog = false"
          class="event-dialog__button"
        >
          다시 시도하기
        </v-btn>  
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import axios from "axios";
  import GreenerEventSuccess from './GreenerEventSuccess' 
  export default {
    components: {
      GreenerEventSuccess
    },
    data: () => ({
      currentQuizIndex: 0,
      success: false,
      dialog: false,
      valid: true,
      showForm: true,
      isLoading: false,
      form: {
        name: '',
        group: '',
        email: '',
        phone: '',
        address: '',
        check1: false,
        check2: false,
        checkMarketing: false
      },
      nameRules: [v => !!v || "이름을 입력해주세요."],
      groupRules: [v => !!v || "소속을 입력해주세요."],
      emailRules: [
        v => !!v || "이메일 주소를 입력해주세요.",
        v => /.+@.+\..+/.test(v) || "이메일 주소 형식이 아닙니다."
      ],
      phoneRules: [
        v => !!v || "휴대폰 번호를 입력해주세요.",
        v => /^[0-9]*$/.test(v) || "형식에 맞지않는 번호 입니다.",
        v => (v && v.length <= 11) || "형식에 맞지않는 번호 입니다."
      ],
      addressRules: [v => !!v || "배송 주소를 입력해주세요."],
      checkRules: [v => !!v], 
      questions: [
        {
          num: 1,
          text: '녹색 화학의 원리에는 총 <span class="vdn">10</span>개가 개발되었다',
          hint: '실험실에서 직면하는 환경적 문제를 해결하기 위해 <span class="vdn">1991</span>년 그린 화학 <span class="vdn">12</span>개 원리가 개발되었다.',
          answer: false
        },
        {
          num: 2,
          text: '머크의 지속가능한 제품 개발을 위해 타사의 <span class="vdn">Dfs</span> 원칙을 활용한다.',
          hint: '<span class="vdn">2014</span>년 머크는 제품의 수명 주기의 각 단계에서 지속가능성을 최우선으로 삼는 <span class="vdn">Design for sustainability</span> 설계방식을 만들었다.',
          answer: false
        },
        {
          num: 3,
          text: '<span class="vdn">DOZN™</span>은 다양한 제품과 공정을 녹색 화학 원칙에 맞추어 평가하는 머크만의 녹색 화학 평가 장치이다.',
          hint: '<span class="vdn">DOZN™</span> 스코어링을 통해 과학자 및 연구원들은 친환경 대안과 친환경이 무엇인지 더욱 잘 설명할 수 있게 되었다.',
          answer: true
        },
        {
          num: 4,
          text: '<span class="vdn">Stericup</span>® 및 <span class="vdn">Steritop</span>® 머크의 친환경 제품에 해당한다.',
          hint: '머크에는 <span class="vdn">Stericup</span>®<span class="vdn">E</span> 및 <span class="vdn">Steritop</span>®<span class="vdn">E</span> 제품도 존재한다.',
          answer: false
        },
        {
          num: 5,
          text: '머크에서는 친환경 용매를 판매하고 있다.',
          hint: '<span class="vdn">Cyrene™</span>는 기존의 유기 용매를 대체할 친환경 용매에 해당한다.',
          answer: true
        }
      ]
    }),
    methods: {
      startQuiz() {
        if (this.$refs.form.validate()) {
          this.showForm = false;
          this.currentQuizIndex = 0;  
        }
      },
      clickTrue(v) { 
        //true 
        if (v == true) {
          if (this.currentQuizIndex < this.questions.length-1) {
            this.currentQuizIndex++;
          } else {
            this.send(); 
          }
        } else {
          this.dialog = true;
        }
      },
      clickfalse(v) {  
        //false
        if (v == false) {
          if (this.currentQuizIndex < this.questions.length-1) {
            this.currentQuizIndex++;
          } else {
            this.send();
          }
        } else {
          this.dialog = true;
        } 
      },
      send() { 
        this.isLoading = true;
        const data = {
          name: this.form.name,
          group: this.form.group,
          email: this.form.email,
          phone: this.form.phone,
          address: this.form.address,
          checkMarketing: this.form.checkMarketing
        }  
        axios
          .post("https://us-central1-merck-earth-quiz-event.cloudfunctions.net/sendEvent", data)
          .then(() => {  
            this.success = true; 
            window.scrollTo(0, 0);  
          })
          .catch(() => {  
            alert("다시 시도해주세요.");
          })
          .finally(() => {
            this.isLoading = false;
          });  
      }
    } 
  }
</script>

<style lang="scss">
.quiz-event {
  position: relative;
  width: 100%; 
  max-width: 1200px; 
  margin: 0 auto;
  padding-bottom: 65px;
  background-color: #b4dc96;
  background-image: url('../assets/images/main/greener/bg_quiz.png');
  background-position: center 200px;
  background-size: 100%;
  background-repeat: no-repeat;
  font-family: 'Noto Sans KR', Arial, Helvetica, sans-serif;
  font-size: 16px;
  text-align: center; 
  word-break: keep-all; 
  box-sizing: border-box;
  .floating-link {
    height: 130px;
    .link {
      position: absolute;
      top: 60px;
      left: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      text-decoration: none;
      .icon {
        width: 40px;
        margin-right: 16px;
      }
      .text {
        display: inline-block; 
        font-weight: 700;
        font-size: 26px;
        color: $darkblue;  
        .vdn {
          font-weight: 500;
        } 
        br {
          display: none;
        }
      }
    }
  }
  .logo {
    position: absolute;
    top: 68px;
    right: 68px; 
    img {
      margin-left: auto;
      height: 34px; 
    } 
  }
  .event-title {
    margin-top: 120px;
    &__icon {
      .icon-sprout {
        width: 80px;
        margin: 0 auto;
        margin-bottom: 12px;
      }
    }
    &__campaign { 
      margin-bottom: 40px;
      text-align: center;
      img {
        margin: 0 auto;
      }
    }
    &__title { 
      margin-bottom: 0.659em;
      font-size: 82px;
      font-weight: 900;  
      letter-spacing: -0.035em;
      color: $darkblue;  
      .vdn {
        font-weight: 700;
      }
    } 
  }
  .event-date {
    display: inline-block;
    margin-bottom: 70px;
    .date-list {
      display: flex; 
      align-items: center; 
      background: #dbfbc3;
      width: 784px;
      border-radius: 999px;
      .label {
        min-width: 8.000em;
        padding: 0.500em;
        background: $darkblue;
        border-radius: 999px;
        text-align: center;
        font-weight: 700;
        font-size: 36px;
        line-height: 1;
        letter-spacing: -0.04em;
        color: $white;
      }
      .date {
        margin-left: 0.556em;
        margin-right: 0.833em;
        font-size: 36px;
        font-weight: 700;
        color: $darkblue;
        letter-spacing: -0.04em;
        .vdn {
          font-weight: 500;
        }
        .sub {
          font-size: 32px;
          font-weight: 500;
        }
      }
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    } 
  }
  .event-summary {
    margin-bottom: 2.250em;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -0.04em;
    line-height: 1.3;
    color: $darkblue; 
    .summary1 {
      margin-bottom: 12px;
    }
    .summary2 {
      margin-bottom: 10px;
      font-weight: 900;
      color: $darkgreen;
    }
    .summary3 {
      font-weight: 500;
      font-size: 30px;
      color: $darkgreen;
    }
    .vdn.semi-bold {
      font-weight: 500;
    }  
  }
  .event-gift {
    margin-bottom: 470px;
    text-align: center; 
    .img-gift {
      margin: 0 auto;
    } 
  }
  .event-contents {
    position: relative; 
    margin-bottom: 50px; 
    padding: 0 95px;  
    &__icon {
      position: absolute;
      left: 50%;
      bottom: 100%;
      transform: translateX(-50%); 
    }
    .content {
      padding: 60px 85px;
      border-radius: 50px;
      background: #d8f2c4;
      box-shadow: 0 0 0 15px #c7e5b0;  
      .event-quiz-form {
        text-align: left; 
        width: 100%;
        max-width: 540px; 
        margin: 0 auto;
        &__title {
          display: flex;
          align-items: center;
          flex-direction: column;
          margin-bottom: 60px;
          color: $darkblue;
          text-align: center; 
          .main {
            position: relative;
            margin-bottom: 0.600em;
            z-index: 1;
            font-size: 40px;
            font-weight: 900;
            letter-spacing: -0.04em;
            &::after {
              content: '';
              position: absolute;
              left: -1%;
              bottom: -0.175em;
              width: 102%;
              height: 0.675em;
              z-index: -1;
              background: $lightblue;
            }
          }
          .sub {
            font-size: 28px;
            line-height: 1.2;
            letter-spacing: -0.04em;
          }
        }
        .event-form { 
          &__label {
            font-size: 20px;
            font-weight: 700;
            letter-spacing: -0.05em;
            line-height: 1;
            color: $black;    
          }
          &__input {
            margin-top: 0;
            padding-top: 0.500em;
            margin-bottom: 1.000em;
            font-size: 20px;
            input {  
              font-family: 'Noto-verdanaPro';
              max-height: 40px;
            }
          } 
          &__checkbox {  
            .checkbox-label {
              margin-left: 0.500em;
              font-family: 'Noto-verdanaPro';
              font-size: 20px;
              font-weight: 700;
              letter-spacing: -0.04em;
              color: #303030; 
              .vdn {
                font-weight: 700;
              }
            }
            .error--text { 
              .checkbox-label {
                color: currentColor;
              }
            } 
            .v-input--selection-controls__input {
              width: 34px;
              height: 34px;
              .v-icon.v-icon {
                font-size: 34px;
              }
            } 
            &.v-input--selection-controls {
              margin-top: 10px;
              padding-top: 0;
            }
          }
          &__terms {
            margin-top: 0.857em;
            margin-bottom: 1.429em;
            padding: 1.143em;
            background: #effae7;
            border-radius: 8px;
            font-family: 'Noto-VerdanaPro';
            font-size: 14px;
            font-weight: 400;
            line-height: 1.4;
            letter-spacing: -0.05em;
            a {
              font-weight: 700;
              color: $darkblue;
            }
          }
          &__button { 
            display: block;
            margin: 0 auto;
            margin-top: 2.071em;
            width: 100%;
            max-width: 320px;
            height: 72px !important;
            border-radius: 0.357em;
            font-size: 28px;
            font-weight: 700;
            letter-spacing: -0.04em;
            color: $white; 
            &.v-btn--disabled {
              color: #d8f2c4 !important;
              background: #c1dbad !important;
            }
          } 
        }
      }
      .event-quiz {
        &__num {
          margin-bottom: 0.667em;
          font-family: 'Verdana Pro';
          font-size: 48px;
          font-weight: 900;
          letter-spacing: 0;
          color: #149b5f; 
        }
        &__question {
          margin-bottom: 2.000em;
          font-family: 'Noto-VerdanaPro';
          font-weight: 500;
          font-size: 36px;
          color: $darkblue;
          letter-spacing: -0.05em;
          line-height: 1.3;
          word-break: keep-all; 
          .vdn {
            font-weight: 400;
          }
        }
        &__answer {
          display: flex;
          align-items: center;
          justify-content: space-between;  
          margin-bottom: 50px;
          .btn-anwser {
            width: 380px;
            height: 380px; 
            cursor: pointer;
            transition: all 0.1s;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            -webkit-tap-highlight-color: transparent; 
            &.button-o {
              background-image: url('../assets/images/buttons/greener/btn_o.png');  
              &:active {
                background-image: url('../assets/images/buttons/greener/btn_o_active.png');  
              }
            }
            &.button-x {
              background-image: url('../assets/images/buttons/greener/btn_x.png');  
              &:active{
                background-image: url('../assets/images/buttons/greener/btn_x_active.png');  
              }
            }
          }
        }
        &__hint {
          display: flex;  
          justify-content: center;  
          .icon {
            margin-right: 12px;
            flex-shrink: 0; 
          }
          .text {
            padding-top: 0.308em;
            text-align: left;
            font-family: 'Noto-VerdanaPro';
            font-weight: 500;
            font-size: 26px;
            letter-spacing: -0.05em;
            color: $darkblue;
            word-break: keep-all;
            line-height: 1.3; 
            .vdn {
              font-weight: 400;
            }
          }
        } 
      }
    }
  } 
} 
.v-dialog {
  border-radius: 10px !important;
}
.event-dialog {
  padding-top: 40px;
  border-radius: 10px !important;
  text-align: center;
  &__icon {
    width: 100px;
    margin: 0 auto;
    margin-bottom: 23px;
  }
  &__text {
    padding-bottom: 40px;
    color: $darkblue;
    .main {
      margin-bottom: 0.643em;
      font-size: 28px;
      font-weight: 900;
      letter-spacing: -0.04em;
    }
    .text {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: -0.05em; 
    } 
  }
  &__button.v-btn {
    height: 72px !important; 
    .v-btn__content { 
      font-size: 28px;
      font-weight: 700;
      color: #ffffff;
      letter-spacing: -0.04em; 
      line-height: 1;
    } 
  } 
}
@media (max-width: 1200px) {
  .quiz-event { 
    padding-bottom: 5.4vw;
    background-position: center 16.7vw;
    .floating-link {
      height: 10.8vw;
      .link { 
        top: 5vw;
        left: 5vw; 
        padding: 0.83vw; 
        .icon {
          width: 3.33vw;
          margin-right: 1.33vw;
        }
        .text { 
          font-size: 2.16vw;  
        }
      }
    }
    .logo {
      top: 5.6vw;
      right: 5.6vw;  
      img {
        margin-left: auto;
        height: 3vw; 
      } 
    }
    .event-title {
      margin-top: 10vw;
      &__icon {
        .icon-sprout { 
          width: 6.66vw; 
          margin-bottom: 1vw;
        }
      }
      &__campaign {
        margin-bottom: 3.33vw; 
        img {
          width: 40vw;
        }
      }
      &__title { 
        font-size: 6.83vw;
      } 
    }
    .event-date {
      margin-bottom: 5.833vw;
      .date-list {
        width: 65.2vw;
        .label {
          font-size: 3vw;
        }
        .date {
          font-size: 3vw;
          .sub {
            font-size: 2.666vw;
          }
        }
        &:not(:last-child) {
          margin-bottom: 1vw;
        }
      }
    }
    .event-summary {
      font-size: 3.33vw; 
      .summary1 {
        margin-bottom: 1vw;
      }
      .summary2 {
        margin-bottom: 0.83vw;
      }
      .summary3 {
        font-size: 2.5vw;
      }
    }
    .event-gift {
      margin-bottom: 39.16vw; 
      .img-gift {
        width: 80vw;
      }  
    } 
    .event-contents {
      margin-bottom: 4.16vw; 
      padding: 0 7.9vw;
      &__icon {
        width: 54.16vw; 
      }
      .content {
        padding: 60px 7.08vw;
        border-radius: 4.1vw; 
        box-shadow: 0 0 0 1.25vw #c7e5b0;   
        .event-quiz {
          &__num {
            font-size: 5vw;  
          }
          &__question {
            margin-bottom: 1.200em;
            font-size: 4vw;  
          }
          &__answer {
            margin-bottom: 4.16vw; 
            .btn-anwser {
              width: 31vw;
              height: 31vw;   
            }
          }
          &__hint {
            .icon {
              margin-right: 1vw;
              img {
                width: 11vw;
              }
            }
            .text { 
              font-size: 3vw;  
            }
          }
        }
      } 
    }
  }
}
@media (max-width: 991px) {
  .quiz-event { 
    background-position: center 20vw;
    .floating-link { 
      .link {    
        .icon {
          width: 3.8vw; 
        }
        .text {   
          font-size: 2.4vw;
        }
      }
    }
    .event-title {
      &__icon {
        .icon-sprout { 
          width: 7vw; 
          margin-bottom: 1.21vw;
        }
      }
      &__campaign {
        img {
          width: 45vw;
        }
      }
      &__title { 
        font-size: 7.2vw;  
      } 
    } 
    .event-date {
      .date-list {
        width: 70vw;
        .label {
          font-size: 3.2vw;
        }
        .date {
          font-size: 3.2vw;
        }
      }
    }
    .event-summary {
      font-size: 3.8vw;  
      .summary3 {
        font-size: 2.7vw;
      }
    } 
    .event-contents {
      padding: 0 7.5vw; 
    } 
  } 
}
@media (max-width: 800px) {
  .quiz-event {
    .floating-link { 
      .link {   
        align-items: flex-start;
        .icon {
          width: 4.5vw;
        }
        .text {  
          text-align: left;
          line-height: 1.2;
          font-size: 3vw;
          br {
            display: block;
          }
        }
      }
    }
    .event-contents {
      .content {
        padding: 6vw 8vw;
        .event-quiz-form {
          margin: 1vw 0;
          &__title {
            margin-bottom: 8vw;
            .main {
              font-size: 5.6vw; 
            }
            .sub {
              font-size: 4vw;
            }
          }
          .event-form {
            &__label {
              font-size: 4vw;
            }
            &__input {
              font-size: 4vw;
            }
            &__checkbox {
              .checkbox-label {
                font-size: 4vw;
              }
              .v-input--selection-controls__input {
                width: 5.5vw;
                height: 5.5vw;
                .v-icon.v-icon {
                  font-size: 5.5vw;
                }
              } 
            }
            &__terms {
              font-size: 1.75vw;
            }
            &__button {
              height: 12vw !important; 
              font-size: 4.5vw;
            }
          }
        }
      }
    }
  } 
  .v-dialog { 
    border-radius: 5px;
  }
  .event-dialog {
    padding-top: 4vw;
    border-radius: 5px;
    &__icon {
      width: 12.5vw; 
      margin-bottom: 3.5vw;
    }
    &__text {
      padding-bottom: 5vw; 
      .main {
        margin-bottom: 0.5em;
        font-size: 5vw;
      }
      .text {
        font-size: 4vw;
      }
    } 
    &__button.v-btn {
      height: 12vw !important; 
      .v-btn__content { 
        font-size: 4vw; 
      } 
    } 
  }
}
@media (max-width: 576px) {
  .quiz-event {
    .event-contents {
      .content {
        .event-quiz-form {
          .event-form {
            &__terms {
              font-size: 11px;
            }
          }
        }
      }
    }
  }

}
</style>