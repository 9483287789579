<template>
  <div> 
    <GreenerEvent />
    <Footer />
  </div>
</template>

<script> 
import GreenerEvent from './components/GreenerEvent';
import Footer from '@/components/Footer';

export default {
  name: 'App',

  components: { 
    GreenerEvent,
    Footer
  },

  data: () => ({
    //
  }),
};
</script>
